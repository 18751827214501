.profile-details-container {
  padding: 0 2rem 0.5rem;
  display: flex;

  @media screen and (max-width: 566px) {
    padding-top: 1rem;
  }
}

.accordion {
  .DatePicker {
    margin-left: 40px;
  }
}

.checkbox-group {
  i {
    width: 1.4rem;
  }
}
