$activeBarWidth: calc(100% - (0.66667rem * 2));

.elo-navbar {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0 1.5rem 2rem;

  @media screen and (max-width: 610px) {
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .profile-hover {
    position: relative;
    margin-top: 0;

    .profile.base {
      margin-left: 0;
    }
  }

  .navbar-brand {
    padding: 0;
    margin-right: 0 !important;
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;

    .appName {
      position: relative;
      margin-top: 0;
      margin-left: 0;
      font-weight: 600;
      width: auto;
      @media screen and (min-width: 961px) {
        margin-right: 4.5rem;
      }
      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    .badge-info {
      top: 2rem;
      @media screen and (min-width: 611px) {
        margin-top: 1rem;
        position: absolute;
      }
    }
  }

  .nav-link.dropdown-toggle {
    > i {
      display: none;
    }
  }

  .navbar-nav {
    margin-left: 0 !important;
    @media screen and (max-width: $page-break-t) {
      margin-left: auto !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
    @media screen and (max-width: 566px) {
      position: absolute;
      right: 4rem;
    }

    a[title='More'] {
      font-size: 0rem;
    }
  }

  .nav-item {
    @media screen and (max-width: $page-break-t) {
      .nav-link {
        padding: 0 !important;
        margin-right: 0.5rem;
      }
    }

    &.active {
      background-color: transparent;

      .nav-link.active {
        font-weight: 600;
        background-color: transparent;
        color: $elo-info;

        &::after {
          content: '';
          width: $activeBarWidth;
          height: 0.2rem;
          background-color: black;
          position: absolute;
          top: 2.3rem;
          left: 50%;
          margin-left: calc((#{$activeBarWidth} / 2) * -1);
        }
      }
    }

    .nav-link {
      color: $elo-info;
      transition: none;

      &:hover {
        background-color: transparent;
        &::after {
          content: '';
          width: $activeBarWidth;
          height: 0.2rem;
          background-color: black;
          position: absolute;
          top: 2.3rem;
          left: 50%;
          margin-left: calc((#{$activeBarWidth} / 2) * -1);
        }
      }
    }
  }

  > .elo-theme.elo-bounce.detail-wrapper {
    background-color: transparent;
  }
}

.modal.containerless.fade .modal-dialog .modal-content {
  @media screen and (max-width: 960px) {
    height: auto !important;
    .modal-body {
      height: auto !important;
    }
  }
}

.modal-content {
  .mobile-nav-list {
    padding: 1rem;
    background-color: $elo-info;
    .nav-link {
      color: $elo-secondary;
      transition: none;

      &:hover {
        background-color: transparent;
        &::after {
          content: '';
          width: $activeBarWidth;
          height: 0.2rem;
          background-color: $elo-secondary;
          position: absolute;
          top: 2.3rem;
          left: 50%;
          margin-left: calc((#{$activeBarWidth} / 2) * -1);
        }
      }
    }
    .nav-item.active {
      background-color: transparent;
      margin-bottom: 0.6rem;

      .nav-link.active {
        font-weight: 600;
        background-color: transparent;
        color: $elo-secondary;

        &::after {
          content: '';
          width: $activeBarWidth;
          height: 0.2rem;
          background-color: $elo-secondary;
          position: absolute;
          top: 2.3rem;
          left: 50%;
          margin-left: calc((#{$activeBarWidth} / 2) * -1);
        }
      }
    }
  }
  .close {
    color: $elo-secondary;
    opacity: 1;
  }
}

.elo-bounce {
  .profile-hover {
    .profile {
      background-color: darken($elo-secondary, 15) !important;
      margin-left: 0;
      &:hover {
        background-color: darken($elo-secondary, 25) !important;
      }
      .profile-details {
        .subtitle {
          background-color: $elo-primary;
        }
      }
    }
  }
}

.detail-wrapper {
  .elo-navbar {
    .nav-item {
      .nav-link {
        color: $white;
        &::after {
          background-color: $white;
        }

        &.active {
          color: $white;
          &::after {
            background-color: $white;
          }
        }
      }
    }
    .navbar-brand {
      .appName {
        color: $white;
      }
    }
  }
  .siteheader {
    path {
      fill: rgb(194, 192, 191);
    }
    rect {
      fill: rgb(255, 255, 255);
    }
  }
}

.profile-hover .profile .profile-details {
  .name,
  .subtitle {
    @mixin name-container {
      max-width: 144px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @media screen and (min-width: 961px) and (max-width: 1150px) {
      @include name-container();
    }
    @media screen and (max-width: 730px) {
      @include name-container();
    }
  }
}
