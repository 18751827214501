.elo-bounce,
.modal {
  .btn-link-basic {
    color: $elo-primary;

    &:hover {
      color: darken($elo-primary, 0.5) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem transparentize($elo-primary, 0.8);
    }
  }

  .btn-primary {
    background-color: $elo-primary;
    border-color: $elo-primary;
    box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.8);
    &:hover,
    &:active,
    &:focus {
      background-color: darken($elo-primary, 10) !important;
      border-color: darken($elo-primary, 10) !important;
      box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.7) !important;
    }
  }

  .btn-outline-primary {
    border-color: $elo-primary;
    color: $elo-primary;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($elo-primary, 0.5) !important;
      border-color: $elo-primary !important;
      box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.7) !important;
      color: $white;
    }
  }

  .form-control,
  .btn {
    &:focus {
      box-shadow: 0 0 0 0.2rem transparentize($elo-primary, 0.8);
    }
  }
}

.clear-filters {
  padding: 1.2rem 2rem;
  .btn {
    color: $elo-primary;

    i {
      margin-left: 0 !important;
    }

    &:hover {
      color: darken($elo-primary, 0.5) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem transparentize($elo-primary, 0.8);
    }
  }
}
