.filter-btn {
  @media only screen and (min-width: 922px) {
    display: none;
  }
  span:first-child {
    margin-right: 0.5rem;
  }
}

.master-detail {
  .detail {
    .desktop-navigation,
    .mobile-navigation {
      display: none !important;
    }
  }
  .master {
    .skeleton-loader {
      padding: 2rem;
    }
  }
}

.opportunities-list.category-cards {
  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1900px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 2200px) {
    grid-template-columns: repeat(5, 1fr);
  }
  .category-card {
    @media screen and (min-width: 768px) {
      .badge + .category-card-content {
        padding-top: 3rem;
      }
      .category-card-content {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }

    h4 {
      line-height: 1.3;
      margin-bottom: 1rem;
    }
  }
}

.opportunities-list.category-cards,
.skeleton-loader.category-cards {
  padding-left: calc(3rem - 15px);
  padding-right: calc(3rem - 15px);

  @media screen and (max-width: 922px) {
    padding-left: calc(2rem - 15px);
    padding-right: calc(2rem - 15px);
  }
}

.alert {
  margin-left: calc(3rem - 15px) !important;
  margin-right: calc(3rem - 15px) !important;

  @media screen and (max-width: 922px) {
    margin-left: calc(2rem - 15px) !important;
    margin-right: calc(2rem - 15px) !important;
  }
}
.opportunity-list-header {
  align-items: center;
  padding-left: calc(3rem - 15px);
  flex-wrap: wrap;
  @media screen and (max-width: 922px) {
    padding-left: calc(2rem - 15px);
  }
  .btn {
    color: $elo-primary;
    margin-left: -0.25rem;

    &:hover {
      color: darken($elo-primary, 10) !important;
    }

    i {
      font-weight: 400;
    }
  }
  p {
    margin-bottom: 0;
  }

  h4 {
    display: unset !important;
    margin-bottom: 0;
  }
}
