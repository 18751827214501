.application {
  &-form-field {
    textarea,
    input {
      background: rgba($color: #b1b1b1, $alpha: 0.12);

      &:focus,
      &:hover {
        box-shadow: unset;
      }
    }
    label {
      font-size: 0.9rem;
      font-family: 'Beatrice' !important;
    }
  }

  &-header {
    padding: 0 2rem 2rem;
    align-items: center;
    @media screen and (max-width: 480px) {
      padding-left: 0;
      padding-right: 0;
    }
    &-icon {
      width: 6rem;
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
    &-instructions {
      @media screen and (min-width: 481px) {
        padding-left: 4rem;
      }
      font-size: 1rem;
      line-height: 1.5;
    }
    i {
      font-size: 7rem;
      width: 6rem;
    }
  }

  &-upload {
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem;
    background-color: rgba($color: #b1b1b1, $alpha: 0.14);
    flex-wrap: wrap;

    @media screen and (max-width: 991px) {
      .upload-area {
        width: 100%;
      }
      &-instructions {
        padding-top: 1rem;
      }
    }
    .upload-area {
      border: 2px dashed #707070;
      min-width: 14rem;
      order: 1;

      &:hover {
        border: 2px dashed #474747;
        box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.1);
      }
    }
    &-instructions {
      order: 2;
      @media screen and (min-width: 992px) {
        padding-left: 3rem;
        flex-basis: 50%;
      }
    }
    .uploaded-files {
      order: 3;
      flex-basis: 100%;
      background-color: $white;
      padding: 1rem;
      border-radius: 0.75rem;
    }
  }

  &-read-only {
    p:last-child {
      font-weight: 600;
    }
  }
}

.uploaded-file {
  justify-content: flex-start !important;
}

.modal div,
.modal h3,
.modal .btn {
  font-family: 'Beatrice' !important;
}

.modal-body {
  padding-top: 0 !important;
}

.modal-content {
  border-radius: unset !important;
}

div[aria-labelledby='Application-label'] {
  .modal {
    h4 {
      font-size: $h3-font-size;
    }
    p {
      line-height: 1.5;
    }
  }
  .modal-header {
    border-bottom: hidden;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .modal-footer {
    border-top: hidden;
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .close {
    color: $elo-info;
  }
}
