@font-face {
  font-family: 'Beatrice';
  src: local('Beatrice'), url('../Fonts/beatrice-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Beatrice';
  font-weight: 600;
  src: local('Beatrice Bold'), url('../Fonts/beatrice-bold.woff2') format('woff2');
}

body {
  font-family: 'Beatrice' !important;
  background-color: $white !important;
}

.elo-bounce {
  background: $elo-secondary;

  .mobile-handle {
    display: none;
  }

  &.show-menu {
    .mobile-handle {
      text-align: center;
      display: block;
      margin-top: -3rem;
      color: black;
      width: 100%;
      font-size: 1.5rem;
      opacity: 0.5;
      padding: 0.5rem;
      pointer-events: none;
      z-index: 10;
    }
  }

  // the parent must be flex, and flex wrap
  .flex-line-break {
    flex-basis: 100%;
    height: 0;
  }

  .icon-end {
    a.isRequired {
      background-color: $elo-secondary;
    }

    button.isRequired {
      background-color: $elo-secondary;
    }
  }

  .info-circle {
    line-height: 1;
    margin-top: 0.2rem;
  }

  *::-webkit-scrollbar-track {
    background-color: $elo-info;
  }

  .advanced-table {
    a:not(.btn) {
      color: $elo-primary;

      &:hover {
        color: darken($elo-secondary, 0.5) !important;
      }
    }

    .sort-icon {
      .btn {
        color: $elo-primary;
      }
    }

    .action-buttons-container {
      .btn {
        color: $elo-primary;

        &:hover {
          background-color: $info;
          border-radius: 50%;
          color: darken($elo-secondary, 0.5) !important;
        }
      }
    }
  }

  .table-controls {
    .btn-sm {
      color: $elo-primary;

      &:hover {
        color: darken($elo-secondary, 0.5) !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem transparentize($elo-primary, 0.8);
      }
    }
  }

  .DatePicker__calendarContainer {
    .Calendar__section {
      .Calendar__day {
        &.-selected {
          background-color: $elo-primary;
          box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.8);

          &:hover {
            background-color: darken($elo-secondary, 0.5) !important;
            box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-secondary, 0.7) !important;
          }
        }

        &.-selectedStart,
        &.-selectedEnd {
          background-color: $elo-primary;

          &:hover {
            background-color: $elo-primary !important;
          }
        }

        &.-selectedBetween {
          background-color: transparentize($elo-primary, 0.3);

          &:hover {
            background-color: $elo-primary !important;
          }
        }
      }
    }

    .Calendar__yearSelector {
      .Calendar__yearSelectorItem.-active {
        .Calendar__yearSelectorText {
          background-color: $elo-primary !important;
          box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.8);

          &:hover {
            background-color: darken($elo-secondary, 0.5) !important;
            box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-secondary, 0.7) !important;
          }
        }
      }
    }

    .Calendar__monthSelector {
      .Calendar__monthSelectorItem.-active {
        .Calendar__monthSelectorItemText {
          background-color: $elo-primary !important;
          box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.8);

          &:hover {
            background-color: darken($elo-secondary, 0.5) !important;
            box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-secondary, 0.7) !important;
          }
        }
      }
    }
  }

  .list-group-item {
    &:hover {
      background: transparentize($elo-primary, 0.7);
    }
  }
  .list-group-item.active {
    background-color: $elo-primary !important;
    &:focus {
      background: $elo-primary !important;
      border-color: $elo-primary !important;
    }
  }

  .basic-layout {
    background-color: $elo-info;
  }
  .master-detail {
    background-color: $elo-info;
    box-shadow: none;
    .master {
      .filtered-list {
        .form-control {
          background-color: lighten($elo-info, 12%);
          &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($elo-primary, 0.8);
          }
        }
      }
    }
    .detail {
      box-shadow: none;
      .detail-contents {
        background: linear-gradient(180deg, $gray-300 0%, $white 20%);
      }
    }
  }

  .search {
    .form-control {
      &::placeholder {
        font-weight: normal;
        color: lighten($black, 65);
      }
    }
  }

  .nav-tabs {
    .nav-item.active {
      background-color: $gray-300;
    }
    .nav-link.active {
      background-color: $gray-300;
    }
  }

  .accordion {
    .card-header {
      &:hover {
        color: $elo-secondary;
      }
    }
  }
  //terrible as these are not good color diffirentiation

  .btn-outline-secondary {
    color: $elo-secondary;
    border-color: $elo-secondary;

    &:hover {
      background-color: darken($elo-secondary, 0.5) !important;
    }
  }

  &.drawer {
    .btn-outline-secondary {
      border-color: $elo-primary;
      color: $elo-secondary;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($elo-primary, 0.5) !important;
        border-color: $elo-primary !important;
        box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.7) !important;
        color: $white;
      }
    }
    .btn-secondary {
      background-color: $elo-primary;
      border-color: $elo-primary;
      box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-primary, 0.8);
      &:hover,
      &:active,
      &:focus {
        background-color: darken($elo-secondary, 0.5) !important;
        border-color: $elo-secondary !important;
        box-shadow: 0rem 0.75rem 1.5rem transparentize($elo-secondary, 0.7) !important;
      }
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $elo-primary;
  }

  .time-popup .row .col .highlight-primary > div {
    background: $elo-primary;
  }
}

.elo-bounce {
  .nav-item:hover,
  .nav-item:focus {
    color: $elo-primary;
  }
  .dropdown {
    width: inherit;
  }
}

@media only screen and (max-width: 566px) {
  .elo-bounce {
    .sidebar {
      background: $elo-secondary;
      .nav {
        .nav-item.active {
          background: $elo-primary;
        }
      }
    }
  }
}

.elo-bounce {
  background: #fff;
  .dropdown {
    width: inherit;
  }
  .detail,
  .master,
  .master-detail {
    border-radius: 0px;
  }

  .master {
    background: rgb(2, 0, 36);
    padding-top: 1rem;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(243, 243, 243, 1) 100%);
  }
  .detail-contents {
    background: #fff !important;
  }
}

.mapboxgl-ctrl {
  display: none !important;
}
