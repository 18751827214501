.filter-options {
  .form-group {
    margin-bottom: 0;
    border-bottom: 1px solid rgba($color: #707070, $alpha: 0.3);

    input[type='checkbox'] {
      margin-left: 3rem;
      margin-right: 0.35rem;
    }

    > div > input[type='checkbox'] {
      margin-left: 1rem;
    }

    &.calendar {
      .card {
        overflow: unset !important;
        z-index: 999;
      }
    }
  }

  .main-search {
    & > .text {
      padding-left: 2rem;
      padding-right: 2rem;
      .btn.btn-primary {
        top: 10px !important;
        right: 35px !important;
        padding-left: 0.6rem;
        padding-right: 0.6rem;

        > i {
          font-weight: 900;
        }
      }
    }
  }

  .checkbox-group-container {
    .dropdown-search {
      padding-left: 2rem;

      .text {
        padding-right: 0;
      }
    }

    .form-control {
      border-bottom: 1px solid #d0d0d0;
      border-radius: 0px;
      background: transparent;
      &:focus,
      &:hover {
        box-shadow: none;
        border-bottom: 1px solid #f61764ab;
      }
    }

    &-items {
      max-height: 20rem;
      overflow-y: auto;
    }

    &-no-filters {
      margin-left: 4rem;
      padding-top: calc(0.75rem + 1px);
      padding-bottom: calc(0.75rem + 1px);
    }
  }

  .checkbox-group {
    display: flex;
    align-items: flex-start;
    padding-top: calc(0.75rem + 1px);
    padding-bottom: calc(0.75rem + 1px);
    padding-right: 0.5rem;

    .col-form-label {
      padding: 0;
      line-height: 1.5;
      margin-top: -3px;
      user-select: none;
    }
  }

  .accordion {
    .card {
      border-radius: 0px !important;
      .card-header {
        border-radius: 0px !important;
        padding: $filter-padding;
        h6 {
          font-weight: normal;
          color: $elo-info;
          background-color: transparent;
        }
      }

      .collapse,
      .collapsing {
        .form-group:last-child {
          border-bottom: 0;
        }
        .form-group {
          margin-left: 2rem;
        }
      }
    }

    .card .accordion .accordion .card-header {
      padding-left: 1rem;
    }
  }
  .auto-complete {
    padding: 0.7rem 2rem;
    .text {
      padding: 0;
      &.compact.icon-start span {
        padding-left: 0 !important;
      }
      > input {
        padding-left: 1.5rem !important;
        background: transparent;
      }
    }
  }
}

.filter-container {
  .profile-filter {
    img {
      border-radius: 50%;
      height: 3.5rem;
      width: 3.5rem;
      padding: 0.25rem;
      margin-right: 1rem;
    }
    .profile-details {
      display: inline;
      font-weight: 800;
      .name {
        display: inline;
        color: $elo-primary;
      }
    }
    .filter-preferences {
      display: inline;
      a {
        color: rgba($color: #000000, $alpha: 0.5);
        text-align: left;

        &:hover {
          color: rgba($color: #000000, $alpha: 0.7) !important;
        }
      }
    }
  }
  .text {
    padding: 0.5rem;
  }
}
