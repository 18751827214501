$box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);

//ActionButton Colors
$error: #8d3433;
$warning: #f1c134;
$success: #a1cae0;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue !default;
$secondary: #adb5bd !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
// $light: $gray-100 !default;
// $dark: $gray-800 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$border-radius: 0.6rem;

$elo-primary: #f61727;
$elo-secondary: #fff;
$elo-info: #000;

$font-size-base: 0.9rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Filter Bar Variables
$filter-padding: 1.2rem 2rem;

// Media Query breakpoints
$page-break-t: 960px;
$page-break-m: 577px;
