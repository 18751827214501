.detail .header {
  h4:first-of-type {
    display: none;
  }
}

.tooltip-inner {
  overflow: hidden;
  max-height: 50ch;
  text-overflow: ellipsis;
}
