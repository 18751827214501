.elo-bounce.my-applications {
  .master-detail {
    .detail {
      min-height: 100% !important;
      height: auto !important;
      position: relative !important;
      left: unset;
    }
  }
}

.my-applications {
  h4 {
    display: unset !important;
  }
}
