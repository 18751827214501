.filter-options {
  .filter-options-btn {
    border-bottom: 1px solid rgba($color: #707070, $alpha: 0.3);
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      margin-top: 0.2rem;
    }

    &:focus-within {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }

    &.active {
      .btn-link-basic,
      i {
        font-weight: 700;
      }
    }

    &:hover {
      background-color: #d1d9e2 !important;
      transition: all 0.3s ease-in-out;
    }

    > span {
      display: block;
      line-height: 0;
      width: 100%;
    }

    .btn-link-basic {
      padding: 0;
      color: $elo-info;
      padding: $filter-padding;
      width: 100%;
      display: block;
      text-align: left;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: $elo-info !important;
      }
    }
  }
}

.sidebar-info-container {
  padding: $filter-padding;

  .sidebar-info {
    p:not(:first-child) {
      font-weight: 700;
    }
    ul {
      font-weight: 700;
      padding-left: 1rem;
    }
  }

  &.experience-detail-sidebar-info {
    @media screen and (min-width: 992px) {
      display: none;
    }
    padding-left: calc(2rem - 15px);
    padding-right: calc(2rem - 15px);
    padding-top: 2rem;
  }
}

.filter-container {
  .sidebar-info-container {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  .apply-cta {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;

    @media screen and (max-width: 566px) {
      padding-top: 2rem;
    }

    &-subtext {
      padding-top: 0.6rem;
      padding-left: 0.3rem;
    }

    .btn {
      font-weight: 700;
      font-size: 1.1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      box-shadow: none !important;
    }
  }
}

.experience-detail {
  padding-left: calc(3rem - 15px);
  padding-right: calc(3rem - 15px);
  line-height: 1.8;
  white-space: pre-wrap;

  @media screen and (max-width: 991px) {
    padding-left: calc(2rem - 15px);
    padding-right: calc(2rem - 15px);
  }
}

.map-container {
  .map {
    max-height: 13rem;
  }
}

.elo-bounce.detail-wrapper {
  .master-detail {
    .detail {
      position: relative;
      height: unset !important;
      left: auto;

      .header {
        h4 {
          @media screen and (max-width: 991px) {
            padding: 2rem 2rem 1rem;
          }
        }
      }
    }
  }
}

.color-card {
  border-radius: 0.3rem !important;
  min-height: 0.8rem;
  max-height: 0.8rem;
  min-width: 0.8rem;
  max-width: 0.8rem;
  margin-right: 0.1rem;
}

.website-link {
  word-wrap: break-word;
}

.experience-detail-handshake {
  @extend .experience-detail;

  white-space: unset !important;
  // line-height: 1.5rem;
  font-size: 14px;
  line-height: 1.428571429;

  b,
  strong {
    font-weight: bold;
  }
  .respect-newlines {
    white-space: pre-wrap;
  }

  .property-box {
    word-wrap: break-word;
    margin: 20px 0;
  }

  p {
    margin: 0 0 9px;
  }

  .tile {
    padding: 6px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 0 4px 1px rgb(0 0 0 / 5%), 2px 2px 2px 1px rgb(0 0 0 / 5%);
    border: 0;
    position: relative;
    margin-bottom: 12px;
    padding-right: 6px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .property-box .filter-label,
  .property-box h4,
  .nav-header,
  .nav-header-student {
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    color: #707070;
  }

  .property-box h4 {
    margin-bottom: 0.2rem;
  }

  br {
  }
}
