.elo-bounce {
  height: 100%;
  display: flex;
  flex-direction: column;

  section.mainbar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .elo-hero {
    margin-top: 1rem !important;
  }

  .master-detail {
    @media only screen and (max-width: 922px) {
      overflow-x: hidden;

      .detail {
        height: 100% !important;
      }
    }
    @media only screen and (min-width: 923px) {
      .master,
      .detail {
        transition: none;
        animation: none;
      }
    }

    flex-grow: 1;
    height: auto;
    display: flex;
    flex-direction: column;

    > .row {
      height: unset !important;
      flex-grow: 1;
      .master {
        height: unset !important;
        max-height: unset !important;
      }
      .detail {
        height: unset;
      }
    }
  }

  .elo {
    // &-top-container {
    // 	display: flex;
    // 	flex-direction: column;
    // }
    &-hero {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
      color: white;
      margin-top: 4.5rem;
      flex-wrap: wrap;

      &-info {
        .btn {
          font-size: $h5-font-size;
          color: $white;
          padding: 0 0 0.5rem 0;

          &:hover {
            color: darken($white, 20%) !important;
          }

          i {
            font-weight: 700;
            margin-left: 0 !important;
          }
        }
        h4 {
          font-size: $h5-font-size;
          font-weight: 400;
        }
      }

      &-cta {
        align-self: flex-end;
        margin-bottom: 0.5rem;
        .btn {
          max-width: 14rem;
          font-size: 20px;
          font-weight: 700;
        }
        h5 {
          padding-top: 0.5rem;
        }
      }
    }
  }
  .experiences {
    flex-grow: 1;
    height: auto;
    display: flex;

    .detail {
      .detail-contents {
        @media screen and (max-width: 921px) {
          overflow: unset !important;
        }
      }
    }
  }
}

.col-lg-3 {
  @media (min-width: 2000px) {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
}

.col-lg-9 {
  @media (min-width: 2000px) {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
}
