.list-group-item {
  &:first-child {
    border-top: 1px solid lighten($elo-info, 75) !important;
  }
  border-radius: 0px !important;
  margin-top: 0rem;
  margin-bottom: 0rem;
  p {
    color: $elo-info;
  }
}

.calendar-label {
  float: left;
  margin-left: 1.2rem;
}

.elo-bounce {
  .master-detail {
    .detail {
      .header {
        h4 {
          padding-bottom: 1rem;
          height: auto;
          font-size: $h3-font-size;

          @media screen and (max-width: $page-break-t) {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 0;
          }
        }
      }
    }
  }
}

.intro-text {
  font-weight: 500 !important;
}

.opportunity-list-header {
  h4 {
    padding: 0 !important;
    margin-top: 1.5rem;
  }
}
